import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { connectPayment } from '../../api';
import { Link } from '../../components';
import { useAuth } from '../../hooks';
import { BuyAReviewPage } from '../Account';

import * as Styled from './Register.style';

export const RegisterStepTwo = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate('/?login=true');
    } else if (user && user.reviewed) {
      return navigate('/account');
    }

    if (user && !user.registered) {
      connectPayment().catch((error) => console.error(error));
    }
  }, [user, navigate]);

  return (
    <Styled.Wrapper>
      <div className='container'>
        <div>
          <svg
            width='45'
            height='45'
            viewBox='0 0 45 45'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='goBackLeft'
            onClick={() => navigate('/register')}
          >
            <circle
              cx='22.5'
              cy='22.5'
              r='22'
              stroke='#DFDFDF'
            />
            <g clipPath='url(#clip0_1156_13062)'>
              <path
                d='M28.6667 23H15.3333'
                stroke='#111111'
              />
              <path
                d='M22 16.3332L15.3333 22.9998L22 29.6665'
                stroke='#111111'
              />
            </g>
            <defs>
              <clipPath id='clip0_1156_13062'>
                <rect
                  width='16'
                  height='16'
                  fill='white'
                  transform='matrix(-1 0 0 1 30 15)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <BuyAReviewPage
        title={t('buy_a_review.title')}
        order={{
          name: 'Annual Plan',
          name_key: 'plan.0',
          price: 104,
          priceId: process.env.REACT_APP_ANNUAL_PLAN,
          period: 'year',
          period_key: 'period.0',
          standardPrice: 149,
        }}
        additionalInfo={[
          'Discount is available only and exclusively for subscription made in a period from 08th to 11th July 2024.',
          'Discount is not available for Participants with Master title within PhiAcademy.',
          'Discount will be effective for limited period of 1 (one) year and for Participants, who subscribe for reduced price of 104+VAT in period from 08th to 11th July 2024, their subscription will be renewed at the standard price after the expiration of 1 (one) year of subscription discount.',
        ]}
        mode='subscription'
      />

      <Link
        to='/'
        variant='link'
        className='skipForNow'
        styles={{
          color: '#000',
          textTransform: 'unset',
          textDecoration: 'underline',
          margin: '0 auto',
          display: 'block',
        }}
      >
        {t('buy_a_review.skip')}
      </Link>
    </Styled.Wrapper>
  );
};
