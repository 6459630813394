import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../hooks';
import {
  checkoutSession,
  couponExists,
  validateCouponCode,
} from '../../../api';
import { Button, Card, CheckedUl, PhiLine } from '../../../components';

import * as Styled from './BuyAReview.style';

import LoginImage from '../../../assets/images/subscribeImage.jpg';

export const BuyAReviewPage = ({
  order,
  mode,
  title,
  hideHeader = false,
  additionalInfo,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [enterVoucher, setEnterVoucher] = React.useState(false);
  const [voucherCode, setVoucherCode] = React.useState(null);
  const [couponValid, setCouponValid] = React.useState(false);
  const [voucherError, setVoucherError] = React.useState(false);
  const [showCouponButton, setShowCouponButton] = React.useState(false);

  React.useEffect(() => {
    if (user.email) {
      couponExists(user.email).then((res) => {
        setShowCouponButton(res?.isExists);
      });
    }
  }, [user.email]);

  let orderData = order;
  if (couponValid) {
    orderData = {
      ...order,
      name: 'Annual Plan - Master Voucher',
      price: 0.99,
      priceId: process.env.REACT_APP_ANNUAL_PLAN_MASTER,
    };
  }

  const handleBuyNow = (e) => {
    e.preventDefault();

    checkoutSession({
      order: orderData,
      mode: mode || 'payment',
      coupon: voucherCode,
    })
      .then((res) => {
        // window.dataLayer.push({
        //   event: 'gtm_phiawards_purchase',
        //   currency: 'EUR',
        //   transaction_id: 'custom id',
        //   value: '149',
        // });

        window.location.href = res.data.url;
      })
      .catch(console.error);
  };

  const handleVoucher = (e) => {
    e.preventDefault();

    validateCouponCode(voucherCode)
      .then((res) => {
        setCouponValid(res?.valid);
        setEnterVoucher(false);
      })
      .catch((e) => {
        setVoucherError(e.message);
        console.error(e);
      });
  };

  React.useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  const isUserMaster = user?.titles?.some((title) =>
    title.toLowerCase().includes('master')
  );

  return (
    <Styled.Wrapper>
      <div className='container'>
        {!hideHeader && (
          <>
            <h1>{title || t('buy_a_review.default_title')}</h1>
            <PhiLine width='90%' />

            <p className='subTitle'>{t('buy_a_review.subtitle')}</p>
            <p className='description'>{t('buy_a_review.description')}</p>
          </>
        )}

        <Card
          className='plan'
          borderRadius='4px'
          backgroundColor='#F5F5F5'
        >
          <div className='flex'>
            <div className='wrapper'>
              <h2>{t(`buy_a_review.${orderData.name_key}`)}</h2>
              <hr />
              {orderData.standardPrice && (
                <p
                  className='price'
                  style={{ textDecoration: 'line-through', marginBottom: '0' }}
                >
                  {orderData.standardPrice}€
                </p>
              )}
              <p className='price'>
                {orderData.price}€ / {t(`buy_a_review.${orderData.period_key}`)}
              </p>

              <CheckedUl>
                {t('buy_a_review.features', { returnObjects: true }).map(
                  (feature, index) => (
                    <li key={index}>{feature}</li>
                  )
                )}
              </CheckedUl>
            </div>
            <img
              src={LoginImage}
              alt='Login Women'
            />
          </div>
        </Card>

        <ul className='additional-info'>
          {additionalInfo.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <Button
          className='buyNow flex'
          variant='primary'
          onClick={handleBuyNow}
        >
          <span>{t('buy_a_review.buy')}</span>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_526_2508)'>
              <path
                d='M1.33317 8L14.6665 8'
                stroke='white'
              />
              <path
                d='M7.99984 1.33341L14.6665 8.00008L7.99984 14.6667'
                stroke='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_526_2508'>
                <rect
                  width='16'
                  height='16'
                  fill='white'
                />
              </clipPath>
            </defs>
          </svg>
        </Button>

        {mode === 'subscription' && isUserMaster && !couponValid && (
          <Button
            className='enterVoucher flex'
            variant='voucher'
            onClick={() => setEnterVoucher(true)}
          >
            <span>{t('buy_a_review.enter_voucher')}</span>
          </Button>
        )}

        <Styled.StyledModal
          showModal={enterVoucher}
          closeModal={() => setEnterVoucher(false)}
          className='rounded'
          hideBrand
          hideHeader
          style={{ width: '40%' }}
        >
          <div className='header-wrap'>
            <h3 style={{ textAlign: 'left' }}>
              {t('buy_a_review.enter_voucher')}
            </h3>

            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => setEnterVoucher(false)}
            >
              <path
                d='M18.75 5.25L5.25 18.75'
                stroke='black'
                stroke-linecap='square'
                stroke-linejoin='round'
              />
              <path
                d='M18.75 18.75L5.25 5.25'
                stroke='black'
                stroke-linecap='square'
                stroke-linejoin='round'
              />
            </svg>
          </div>

          <input
            type='text'
            name='coupon'
            placeholder='CODE'
            onChange={(e) => setVoucherCode(e.target.value)}
          />

          {voucherError && (
            <div className='error'>
              <small>{voucherError}</small>
            </div>
          )}

          <Button
            className='flex'
            variant='primary'
            onClick={handleVoucher}
            style={{ marginTop: '32px' }}
          >
            <span>{t('buy_a_review.confirm')}</span>
          </Button>
        </Styled.StyledModal>
      </div>
    </Styled.Wrapper>
  );
};
